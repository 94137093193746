<mat-dialog-content class="center">
  <div mat-dialog-title>
    <div>🤔</div>
    {{ data.titleKey | translate }}
  </div>
  <div *ngIf="data.subtitleKey" class="mb-16">
    {{ data.subtitleKey | translate }}
  </div>
  <div mat-dialog-actions class="mt-0">
    <button mat-flat-button [mat-dialog-close]="false" color="white">
      {{ data.cancelButtonLabelKey | translate }}
    </button>
    <button mat-flat-button [mat-dialog-close]="true" color="primary">
      {{ data.confirmButtonLabelKey | translate }}
    </button>
  </div>
</mat-dialog-content>
